.topHeaderSec {
    background:  #d79d2e;
    padding: 2px 0;
}

.topHeaderInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.freeReturn p {
    color: #fff;
    margin: 0;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
}

.languageOption {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.rightList {
    display: flex;
    align-items: center;
    column-gap: 30px;
}

.pageLinks ul,
.socialMedia ul {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}

.socialMedia ul {
    column-gap: 15px;
}

.pageLinks ul li a {
    color: #fff;
    display: inline-block;
    padding: 3px 8px;
    font-size: 14px;
}

.socialMedia ul li a {
    color: #fff;
}

.optionSelect {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.lang select,
.rupees select {
    background: transparent;
    color: #fff;
    align-items: center;
    display: flex;
    border: 0;
    font-size: 14px;
    border: none;
    outline: none;
}
.lang select{
    padding: 10px 24px;
    border-radius: 5px;
}

.lang select option {
    background-color: #d79d2e;
    color: #fff;
    padding: 12px;
}

.miscel {
    display: flex;
    column-gap: 20px;
}

.socialMedia ul li a.userNameText {
    font-size: 13px;
    text-transform: uppercase;
}

@media (max-width: 991px) {
    .pageLinks ul li a {
        font-size: 12px;
    }
}